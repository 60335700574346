<template>
  <div class="loan_item">
    <div class="inner inner1">
      <!-- <div class="content">
        <div class="left_cell">
          <h2>Are you in need of quick <br>and convenient cash? </h2>
          <p class="inner1_text">Look no further -  We Préstamos is here to help you. We understand that unexpected expenses can arise at any time, and sometimes you just need a little extra financial support.
          </p>
        </div>
        <div class="right_cell">
          <img src="~@/assets/images/loanImg/about_us.png">
        </div>
      </div> -->
    </div>
    <div class="inner inner2">
      <h1>Acerca de Nosotros</h1>
      <div>
        <p>En We Préstamos, nos enorgullece ser tu puente hacia soluciones financieras rápidas y accesibles. Con un compromiso firme hacia la transparencia, la comodidad y la seguridad, nos esforzamos por brindarte la mejor experiencia en servicios de préstamos.
        </p>
        <p>Nuestra misión es empoderarte financieramente al proporcionar acceso inmediato a préstamos sin complicaciones. Creemos en facilitar tu camino hacia el éxito financiero.
          En We Préstamos, creemos en la honestidad y la claridad. Todos nuestros términos y condiciones se presentan de manera transparente para que tomes decisiones informadas.
        </p>
        <p>Gracias por elegir We Préstamos para tus necesidades financieras. Juntos, construyamos un camino hacia un futuro financiero sólido y exitoso. ¡Bienvenido a nuestra familia financiera!</p>
      </div>
    </div>
    <div class="inner inner3">
      <h1>¡Contáctanos!</h1>
      <h2>Estamos comprometidos a escucharte. Si tienes preguntas, comentarios o necesitas asistencia, no dudes en ponerte en contacto con nuestro equipo de servicio al cliente. Tu satisfacción es nuestra prioridad.</h2>
      <div class="content flex jc-sb">
        <!-- background: 'url(' + item.img + ')',backgroundSize:'100% 100%', -->
        <div v-for="(item,index) in inner_3items" :key="index" :style="{ width:'32%'}">
          <div class="item flex ai-c fd-c jc-c">
            <img :src="item.img2">
            <div>
              <p>{{ item.txt }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      inner_3items: [
        { title: 'Address:', txt: 'Correo electrónico de contacto: \n informacion@weprestamos.com', img: require('@/assets/images/loanImg/innner3_bg2.png'), img2: require('@/assets/images/loanImg/email.png') },
        { title: 'Email:', txt: 'Dirección: \n AV. Ejército Nacional Mexicano 754, \n Polanco, Granada, Miguel Hidalgo, \n 11520 Ciudad de México, CDMX, México', img: require('@/assets/images/loanImg/innner3_bg1.png'), img2: require('@/assets/images/loanImg/loa.png') },
        { title: 'Phone：', txt: 'Horario de atención: 9:00 am a 6:30 pm', img: require('@/assets/images/loanImg/innner3_bg3.png'), img2: require('@/assets/images/loanImg/time.png') }
      ]
    }
  },
  methods: {
    downloadApp() {
      window.open('https://play.google.com/store/apps/details?id=we.prestamos.rapido')
    }
  }
}
</script>

<style lang="scss" scoped>
.loan_item{
  .inner{
    width:1400px;
    // padding:50px calc((100% - 1100px) / 2) 50px;
    margin: 0 auto;
    font-family: Arial, Arial;
    h3{
      font-size: 18px;
      color: #333;
    }
    .content{
      padding: 0px calc((100% - 1100px) / 2) 0;
      display: flex;
    }
    .left_cell{
      float: left;
      padding: 60px 0;
      text-align: left;
      h1{
        font-size: 40px;
        margin-bottom: 30px;
      }
      .inner1_text{
        color: #333;
        line-height: 25px;
        margin: 40px 0;
      }
      p{
        font-size: 12px;
        line-height: 30px;
        color: #999999;
      }
      .cell_item{
        width: 267px;
        height: 140px;
        margin-top: 18px;
        border-bottom: 1px solid #EE7E01;
      }
    }
  }
  .bottom-20{
    margin-bottom: 20px;
  }
  .inner1{
    background: url("~@/assets/images/loanImg/inner1_2.png") no-repeat;
    height: 500px;
    background-size: 100% auto;
  }
  .inner2{
    // height: 420px;
    // background:#EEEEEE;
    padding-bottom: 50px;
    h1{
      font-size: 35px;
      text-align: center;
      margin:80px 0 30px;
    }
    div{
      padding: 0 250px;
      p{
        font-size: 17px;
        line-height: 28px;
        margin-bottom: 30px;
      }
    }
  }
  .inner3{
    background: #F5F7F4;
    padding: 50px 0 100px;
    text-align: center;
    h1{
      font-size: 40px;
      margin:60px 0 30px;
    }
    h2{
      padding: 0 240px;
      font-family: Arial, Arial;
      font-weight: normal;
      line-height: 30px;
    }
    h3{
      margin-bottom: 18px;
    }
    .content{
      margin-top: 80px;
      .item{
        background: #FFFFFF;
        border-radius: 15px;
        opacity:0.9;
        // margin: 180px 15px 20px 15px;
        padding: 0px 35px;
        height: 170px;
        img{
          width: 35px;
          margin-bottom: 15px;
        }
        p{
          line-height: 25px;
        }
      }
    }
  }
}
@media only screen and (max-width: 665px){
  .loan_item{
    // padding: 30px 0;
    background: white !important;
    color: #333;
    .inner{
      width: 100vw;
      min-width: calc(100vw) !important;
    }
    .inner1{
      height: 150px !important;
    }
    .inner2{
      width: calc(100vw);
      padding-bottom: 0;
      h1{
        line-height: 40px;
        margin: 0 0 30px;
        font-size: 25px;
      }
      div{
        padding: 0 20px;
        p{
          font-size: 12px;
        }
      }
    }
    .inner3{
      padding: 20px 0 0;
      h1{
        margin: 20px 0;
      }
      h2{
        font-size: 14px;
        padding: 0 20px;
      }
      .content{
        justify-content: center !important;
        flex-direction: column !important;
        align-items: center !important;
        margin-top: 50px;
        div{
          width: 90% !important;
          margin-bottom: 20px;
        }
        .item{
          // margin: 150px 15px 15px 15px;
          margin: 0px auto;
          height: 280px;
          padding: 0;
        }
      }
    }
  }
}
</style>
